<template>
    <div class="openinghours" :class="{ 'openinghours-is-open': nextOpen.nowOpen }">
        <slot :data="{ nextOpen }">
            {{ nextOpen.msg }}
        </slot>
    </div>
</template>

<script>
/*
    Expecting day structure to look like:
    {
        day: dayname / number
        from: number (optional)
        to: number (optional)
    }

    expecting closed structure to look like [timestamp, timestamp]
*/
import openinghours from './helpers';

export default {
    name: 'OpeningHours',
    props: {
        days: {
            type: Array,
            default: () => [],
        },
        closed: {
            type: Array,
            default: () => [],
        },
        translations: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            nextOpen: {},
            msg: '',
            openDays: {},
            strings: {},
            timeout: undefined,
            defaultTranslations: {
                opened: 'Geopend',
                closed: 'Gesloten',
                to: 'tot',
                from: 'vanaf',
                monday: 'Maandag',
                tuesday: 'Dinsdag',
                wednesday: 'Woensdag',
                thursday: 'Donderdag',
                friday: 'Vrijdag',
                saturday: 'Zaterdag',
                sunday: 'Zondag',
            },
        };
    },
    watch: {
        days: {
            handler: 'setOpenDays',
            immediate: true,
        },

        translations: {
            handler: 'setTranslations',
            immediate: true,
            deep: true,
        },
    },
    methods: {
        setOpenDays() {
            this.openDays = openinghours.structureOpenDays(this.days?.[0]?.items);
            this.$nextTick(this.calculateNextOpen);
        },

        setTranslations() {
            this.strings = { ...this.defaultTranslations, ...this.translations };
        },

        calculateNextOpen() {
            const delayInMinutes = 1;
            clearTimeout(this.timeout);
            this.nextOpen = openinghours.findNextOpenMoment(this.openDays, this.closed, this.strings);
            this.timeout = setTimeout(this.calculateNextOpen, delayInMinutes * 60 * 1000);
        },
    },
};
</script>
